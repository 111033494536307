import axios from '@axios'
import CrudRepository from "@/repositories/CrudRepository";

export default class FieldRepository extends CrudRepository {
    url = 'fields';

    store(categoryId,payload) {
        const url = `categories/${categoryId}/${this.url}`
        return axios.post(url, payload);
    }
}
