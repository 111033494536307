import users from '@appCore/router/routes/users'
import auth from './auth'
import home from './home'
import profile from './profile'
import other from './other'

export default [
    ...auth,
    ...home,
    ...profile,
    ...users,
    ...other,
]
