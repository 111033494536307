import axios from '@axios'
import CrudRepository from "@/repositories/CrudRepository";

export default class TeamMembersRepository extends CrudRepository {
    url = "team-members"

    getAll(id, params = null) {
        return axios.get(`${this.url}/${id}`, {params});
    }
}
