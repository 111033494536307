export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@appCore/views/Home.vue'),
    meta: {
      pageTitle: "Home",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
      canNavigate: true,
    },
  },
]
