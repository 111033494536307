import repositories from "@appCore/repositories/Repositories"
import AuthRepository from "@/repositories/AuthRepository";
import UtilsRepository from "@/repositories/UtilsRepository";
import ContractRepository from "@/repositories/ContractRepository";
import CustomFieldRepository from "@/repositories/user/CustomFieldRepository";
import TeamRepository from "@/repositories/TeamRepository";
import SectionRepository from "@/repositories/admin/SectionRepository";
import CategoryRepository from "@/repositories/admin/CategoryRepository";
import FieldRepository from "@/repositories/admin/FieldRepository";
import BasicSectionRepository from "@/repositories/admin/BasicSectionRepository";
import FieldsTemplateRepository from "@/repositories/admin/FieldsTemplateRepository";
import RoleRepository from "@/repositories/RolesRepository";
import AssociatedRolesRepository from "@/repositories/AssociatedRolesRepository";
import TeamMembersRepository from "@/repositories/TeamMembersRepository";
import InvitationRepository from "@/repositories/InvitationRepository";

class RepositoryFactory {
    repositories = {
        authApp: new AuthRepository(),
        utilsResources: new UtilsRepository(),
        categories: new CategoryRepository(),
        sections: new SectionRepository(),
        fields: new FieldRepository(),
        basicSections: new BasicSectionRepository(),
        fieldsTemplate: new FieldsTemplateRepository(),
        contracts: new ContractRepository(),
        customFieldsUser: new CustomFieldRepository(),
        teamsUser: new TeamRepository(),
        teamMembers: new TeamMembersRepository(),
        roleRepo: new RoleRepository(),
        associatedRoleRepo: new AssociatedRolesRepository(),
        invitations: new InvitationRepository(),
    }

    create(name) {
        const repos = {...this.repositories, ...repositories}
        return repos[name]
    }
}

export default new RepositoryFactory()
