export default {
    namespaced: true,
    state: {
        redirect: localStorage.getItem('redirect') || '',
    },
    getters: {
        redirect: state => {
            return state.redirect
        },
    },
    mutations: {
        SET_REDIRECT(state, value) {
            state.redirect = value
        }
    },
    actions: {
        updateForceRedirect({commit}, {redirectType}) {
            let redirectPage = ''
            if (redirectType === 'ForceCreateTeam') {
                redirectPage = 'team-creation'
            } else return
            localStorage.setItem('redirect', redirectPage)
            commit('SET_REDIRECT', redirectPage)
        },
        deleteForceRedirect({commit}) {
            localStorage.removeItem('redirect')
            commit('SET_REDIRECT', '')
        }
    },
}
