export default [
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@appCore/views/auth/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Forgot Password",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/reset-password/:token',
        name: 'auth-reset-password',
        props: 'token',
        component: () => import('@appCore/views/auth/ResetPassword.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Reset Password",
            redirectIfLoggedIn: true,
        },
    },
]
