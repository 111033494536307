import '@appCore/global-components'
import Vue from 'vue'

import {
    BSpinner,
    BFormDatepicker,
    BButtonGroup,
} from 'bootstrap-vue'

Vue.component('b-spinner', BSpinner)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-button-group', BButtonGroup)
