import routes from '@appCore/router/routes'
import {canNavigate} from "@/libs/acl/routeProtection";
import i18n from "@/libs/i18n";
import store from "@/store";

export default class router {
    static routes = routes

    static prop = {
        mode: 'history',
        base: process.env.BASE_URL,
        scrollBehavior() {
            return {x: 0, y: 0}
        }
    }

    static afterEach(to) {
        const appLoading = document.getElementById('loading-bg')
        if (appLoading) {
            appLoading.style.display = 'none'
        }
        document.title = `${process.env.VUE_APP_Name} | ${i18n.t(to.meta.pageTitle)}`
    }

    static beforeEach(to, _, next) {
        const isLoggedIn = store.getters['auth/isLoggedIn'];

        if (isLoggedIn && to.meta.redirectIfLoggedIn) {
            return next({name: 'home'})
        }
        if (!to.meta.redirectIfLoggedIn) {
            if (!isLoggedIn) {
                return next({name: 'login'})
            }
            if (to.name !== "pages-404" && !canNavigate(to)) {
                return next({name: 'not-authorized'})
            }
        }
        return next()
    }
}
