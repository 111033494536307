import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
    namespaced: true,
    state: {
        currentCategory: null,
        sectionsItems: [],
        basicSectionsItems: [],
        basicSectionsTotalItems: 1,
        fieldsTemplate: [],
        fieldsTemplateTotalItems: 1,
        haveChange: false,
    },
    getters: {
        fieldsTemplate(state) {
            return state.fieldsTemplate;
        },
        getFieldsTemplateTotalItems(state) {
            return state.fieldsTemplateTotalItems;
        },
        getSectionsItems(state) {
            return state.sectionsItems;
        },
        getBasicSectionsItems(state) {
            return state.basicSectionsItems;
        },
        getBasicSectionsTotalItems(state) {
            return state.basicSectionsTotalItems;
        },
        getCurrentCategory(state) {
            return state.currentCategory
        },
        getHaveChange(state) {
            return state.haveChange
        }
    },
    mutations: {
        SET_CURRENT_CATEGORY(state, currentCategory) {
            state.currentCategory = currentCategory ? JSON.parse(JSON.stringify(currentCategory)) : null
        },
        SET_FIELDS_TEMPLATE(state, fieldsTemplate) {
            state.fieldsTemplate = fieldsTemplate
        },
        SET_FIELDS_TEMPLATE_TOTAL_ITEMS(state, fieldsTemplateTotalItems) {
            state.fieldsTemplateTotalItems = fieldsTemplateTotalItems
        },
        SET_SECTIONS_ITEMS(state, sectionsItem) {
            state.sectionsItems.push(sectionsItem)
        },
        SET_SECTIONS_ITEMS_TO_EMPTY(state) {
            state.sectionsItems = []
        },
        SET_BASIC_SECTIONS(state, basicSectionsItems) {
            state.basicSectionsItems = basicSectionsItems
        },
        SET_BASIC_SECTIONS_TOTAL_ITEMS(state, basicSectionsTotalItems) {
            state.basicSectionsTotalItems = basicSectionsTotalItems
        },
        SET_HAVE_CHANGE(state, isChanged) {
            state.haveChange = isChanged
        }
    },
    actions: {
        fetchFieldsTemplateFromApi({commit}, params) {
            const repo = RepositoryFactory.create('fieldsTemplate');
            repo.getAll(params)
                .then(res => {
                    const response = res.data
                    commit('SET_FIELDS_TEMPLATE', response.data)
                    commit('SET_FIELDS_TEMPLATE_TOTAL_ITEMS', response.meta.total)
                })
        },
        fetchBasicSectionFromApi({commit}, params) {
            const repo = RepositoryFactory.create('basicSections');
            repo.getAll(params)
                .then(res => {
                    const response = res.data
                    commit('SET_BASIC_SECTIONS', response.data)
                    commit('SET_BASIC_SECTIONS_TOTAL_ITEMS', response.meta.total)
                })
        },

        setCategoryId({commit, state}, categoryId) {
            const currentCategory = categoryId ? state.categories.find(x => x.id === categoryId) : null
            commit('SET_CURRENT_CATEGORY', currentCategory)
        },
        setBasicSectionItems({state}, value) {
            state.basicSectionsItems.push(value);
        }
    }
}
