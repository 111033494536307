import Vue from 'vue'
import axios from 'axios'
import handleError from "@/libs/axios/handleError";
import header from "@/libs/axios/header";

const user = JSON.parse(localStorage.getItem('user'));

const headers = header

if(localStorage.getItem("current_team")){
    const currentTeam = JSON.parse(localStorage.getItem('current_team'))
    headers.current_team_id = currentTeam.id
}

const axiosIns = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(
    response => response,
    error => {
        const {_, response} = error
        const result = handleError(response,error)

        if (result !== 0) {
            return result
        }
        // handle error here

        return Promise.reject(response)
    }
)

export default axiosIns
