import axios from '@axios'
import CrudRepository from "@/repositories/CrudRepository";

export default class TeamRepository extends CrudRepository {
    url = "teams"

    createDefaultTeam(payload) {
        return axios.post(`${this.url}/create-default-team`, payload);
    }

    getCategories(id) {
        return axios.get(`${this.url}/${id}/categories`);
    }

    sendInvitation(id, payload) {
        return axios.post(`${this.url}/${id}/send-invitation`, payload);
    }

    leaving(id) {
        return axios.delete(`${this.url}/${id}/leaving`)
    }

    getContracts(id, params) {
        return axios.get(`${this.url}/${id}/contracts`, {params});
    }
}
