import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {}

function handleLocales(locales, availableLocales) {
    locales.keys().forEach(key => {
        const pathParts = key.split('/').filter(item => !['.', '..'].includes(item) && !item.includes('.json'))
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const group = matched[1]
            const locale = pathParts[0] || group
            if (!availableLocales.includes(locale)) {
                return
            }
            let temp = {}
            let tree = {}
            if (group === 'index') temp = locales(key)
            else temp[group] = locales(key)
            pathParts.reverse().forEach(part => {
                tree = {}
                tree[part] = {...temp, ...tree};
                temp = tree
            })
            tree = Object.keys(tree).length > 1 ? tree : temp
            messages[locale] = {...tree[locale], ...messages[locale]};
        }
    })
}

function loadLocaleMessages(availableLocales) {
    let availableLocalesVar = availableLocales || ['en', 'de'];
    if (typeof availableLocalesVar === 'string') {
        availableLocalesVar = availableLocalesVar.split(',').map(item => item.trim())
    }
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const locales2 = require.context('@/libs/i18n/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

    handleLocales(locales2, availableLocalesVar)
    handleLocales(locales, availableLocalesVar)

    return messages
}

export default {
    locale: localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'en',
    messages: loadLocaleMessages(process.env.VUE_APP_I18N_AVAILABLE_LOCALE),
}
