import Vue from 'vue'
import store from '@/store/index'

Vue.mixin({
    data() {
        return {
            categoryPermissions: {
                updateCategory: "category_update",
                deleteCategory: "category_delete",
                addSection: "category_add_section",
                updateSection: "category_update_section",
                deleteSection: "category_delete_section",
                addField: "category_add_field",
                deleteField: "category_delete_field"
            }
        }
    },
    methods: {
        accessTo(permission, categoryPermissions = []) {
            let permissions = categoryPermissions
            if (permissions.length === 0) {
                const category = store.getters["settings/getCurrentCategory"]
                if (category) permissions = category.permissions || []
            }
            return permissions.includes(this.categoryPermissions[permission])
        }
    }
})
