import RoleRepository from "@appCore/repositories/RoleRepository";
import UserRepository from "@appCore/repositories/UserRepository";
import AuthRepository from "@appCore/repositories/AuthRepository";
import UtilsRepository from "@appCore/repositories/UtilsRepository";

export default {
    roles: RoleRepository,
    users: UserRepository,
    auth: AuthRepository,
    utils: UtilsRepository,
}
