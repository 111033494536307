export default [
    {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/roles-management/roles/index.vue'),
        meta: {
            pageTitle: 'roles.roles.page-title.index',
            breadcrumb: [
                {
                    text: 'roles.roles.page-title.index',
                    active: true,
                },
            ],
            resource: 'roles',
            action: 'index',
        }
    },
    {
        path: '/roles/add',
        name: 'roles-add',
        component: () => import('@/views/roles-management/roles/add.vue'),
        meta: {
            pageTitle: 'roles.roles.page-title.add',
            breadcrumb: [
                {
                    text: 'roles.roles.page-title.index',
                    to: {name: 'roles'},
                    active: false,
                }, {
                    text: 'roles.roles.page-title.add',
                    active: true,
                },
            ],
            resource: 'roles',
            action: 'create',
        },
    },
    {
        path: '/roles/:id',
        name: 'roles-edit',
        component: () => import('@/views/roles-management/roles/edit.vue'),
        meta: {
            pageTitle: 'roles.roles.page-title.edit',
            breadcrumb: [
                {
                    text: 'roles.roles.page-title.index',
                    to: {name: 'roles'},
                    active: false,
                }, {
                    text: 'roles.roles.page-title.edit',
                    active: true,
                },
            ],
            resource: 'roles',
            action: 'update',
        },
    },
]
