import axios from '@axios'
import CrudRepository from "@/repositories/CrudRepository";

export default class CategoryRepository extends CrudRepository {
    url = 'categories';

    getCategorySections(id) {
        return axios.get(`${this.url}/${id}/sections`);
    }

    updateSections(id, payload) {
        return axios.put(`${this.url}/${id}/update/sections`, payload);
    }
}
