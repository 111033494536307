export default [
    {
        path: '/category-access',
        name: 'associated-roles',
        component: () => import('@/views/roles-management/associated-roles/index.vue'),
        meta: {
            pageTitle: 'roles.associated-roles.page-title.index',
            breadcrumb: [
                {
                    text: 'roles.associated-roles.page-title.index',
                    active: true,
                },
            ],
            resource: 'associated-roles',
            action: 'index',
        },
    },
    {
        path: '/category-access/add',
        name: 'associated-roles-add',
        component: () => import('@/views/roles-management/associated-roles/add.vue'),
        meta: {
            pageTitle: 'roles.associated-roles.page-title.add',
            breadcrumb: [
                {
                    text: 'roles.associated-roles.page-title.index',
                    to: {name: 'associated-roles'},
                    active: false,
                }, {
                    text: 'roles.associated-roles.page-title.add',
                    active: true,
                },
            ],
            resource: 'associated-roles',
            action: 'create',
        },
    },
    {
        path: '/category-access/:id',
        name: 'associated-roles-edit',
        component: () => import('@/views/roles-management/associated-roles/edit.vue'),
        meta: {
            pageTitle: 'roles.associated-roles.page-title.edit',
            breadcrumb: [
                {
                    text: 'roles.associated-roles.page-title.index',
                    to: {name: 'associated-roles'},
                    active: false,
                },
                {
                    text: 'roles.associated-roles.page-title.edit',
                    active: true,
                },
            ],
            resource: 'associated-roles',
            action: 'update',
        },
    },
]
