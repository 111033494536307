import axios from '@axios'

export default class UtilsRepository {
    base = "utils"

    getFieldTypes() {
        return axios.get(`${this.base}/fields/types`);
    }

    getCategories() {
        return axios.get(`${this.base}/categories`);
    }

    getCategoriesTemplates() {
        return axios.get(`${this.base}/categories/templates`);
    }

    getAvailableLocales() {
        return axios.get(`${this.base}/locales`);
    }

    getRoles() {
        return axios.get(`${this.base}/user-roles`);
    }

    getAssociatedRoles() {
        return axios.get(`${this.base}/roles/type/associated`)
    }

    getPermissions() {
        return axios.get(`${this.base}/permissions`);
    }

    getFieldsTemplatesNames() {
        return axios.get(`${this.base}/fields/templates/names`);
    }

    getContractStatus() {
        return axios.get(`${this.base}/contract/status`)
    }
}
