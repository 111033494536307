import Vue from 'vue'
import ability from "@/libs/acl/ability";

Vue.mixin({
    data() {
        return {
            AppPermissions: {
                addCategory: {
                    resource: 'categories',
                    action: 'create',
                },
                indexCategories: {
                    resource: 'categories',
                    action: 'index',
                },
                indexFieldTemplate: {
                    resource: 'fields-template',
                    action: 'index',
                },
                addFieldTemplate: {
                    resource: 'fields-template',
                    action: 'create',
                },
                updateFieldTemplate: {
                    resource: 'fields-template',
                    action: 'update',
                },
                deleteFieldTemplate: {
                    resource: 'fields-template',
                    action: 'delete',
                },
                indexBasicSections: {
                    resource: 'basic-sections',
                    action: 'index',
                },
                addBasicSection: {
                    resource: 'basic-sections',
                    action: 'create',
                },
                updateBasicSection: {
                    resource: 'basic-sections',
                    action: 'update',
                },
                deleteBasicSection: {
                    resource: 'basic-sections',
                    action: 'update',
                },
                addRole: {
                    resource: 'roles',
                    action: 'create',
                },
                updateRole: {
                    resource: 'roles',
                    action: 'update',
                },
                deleteRole: {
                    resource: 'roles',
                    action: 'delete',
                },
                addAssociatedRole: {
                    resource: 'associated-roles',
                    action: 'create',
                },
                updateAssociatedRole: {
                    resource: 'associated-roles',
                    action: 'update',
                },
                deleteAssociatedRole: {
                    resource: 'associated-roles',
                    action: 'delete',
                },
                updateTeamInfo: {
                    resource: 'settings',
                    action: 'update_team_info',
                },
                indexTeamMembers: {
                    resource: 'settings',
                    action: 'index_team_members',
                },
                updateTeamMember: {
                    resource: 'settings',
                    action: 'update_team_member',
                },
                deleteTeamMember: {
                    resource: 'settings',
                    action: 'delete_team_member',
                },
                sendInvitationToNewMember: {
                    resource: 'settings',
                    action: 'send_invitation',
                },
                deleteInvitation: {
                    resource: 'settings',
                    action: 'delete_invitation',
                },
                indexContracts: {
                    resource: 'contracts',
                    action: 'index',
                },
                addContract: {
                    resource: 'contracts',
                    action: 'create',
                },
                updateContract: {
                    resource: 'contracts',
                    action: 'update',
                },
                deleteContract: {
                    resource: 'contracts',
                    action: 'delete',
                },
            }
        }
    },
    methods: {
        isCanDo(permission) {
            return ability.can(this.AppPermissions[permission].action, this.AppPermissions[permission].resource)
        },
    }
})
