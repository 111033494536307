import Vue from "vue";
import abilityApp from '@/libs/acl/ability'

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || '',
        ability: localStorage.getItem('ability') ? JSON.parse(localStorage.getItem('ability')) : null,
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        current_team: localStorage.getItem('current_team') ? JSON.parse(localStorage.getItem('current_team')) : null,
    },
    getters: {
        isLoggedIn: state => {
            return !!state.token
        },
        user: state => {
            return state.user
        },
        currentTeam: state => {
            return state.current_team
        },
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_ABILITY(state, value) {
            state.ability = value
        },
        SET_CURRENT_TEAM(state, value) {
            state.current_team = value
        },
    },
    actions: {
        login({dispatch}, { token , user }) {
            dispatch('updateToken',{ token})
            dispatch('updateAuth',{ user})
            dispatch('lang/switch',{ lang: user.lang}, { root: true })
        },
        logout({dispatch}) {
            dispatch('updateToken',{ token: ''})
            dispatch('updateUser',{ user: ''})
            dispatch('updateCurrentTeam',{ currentTeam: null})
            dispatch('updateAbility',{ ability: []})
        },
        updateProfile({dispatch}, { user }) {
            dispatch('updateUser',{ user})
            dispatch('lang/switch',{ lang: user.lang}, { root: true })
        },
        updateAuth({dispatch}, { user }){
            dispatch('updateUser',{user})
            if(user.is_admin) dispatch('updateAbility',{ ability: user.ability})
            else dispatch('updateCurrentTeam',{ currentTeam: user.team})
        },
        updateCurrentTeam({commit,dispatch}, {currentTeam}) {
            let ability = []
            if(currentTeam !== null){
                localStorage.setItem('current_team', JSON.stringify(currentTeam))
                Vue.prototype.$http.defaults.headers.current_team_id = currentTeam.id
                ability = currentTeam.ability
            }else{
                localStorage.removeItem('current_team')
                delete Vue.prototype.$http.defaults.headers.current_team_id
            }
            commit('SET_CURRENT_TEAM', currentTeam)
            dispatch('updateAbility',{ability})
            dispatch('settings/setCategoryId', null, { root: true })
        },
        updateUser({commit}, {user}) {
            if(user !== ''){
                localStorage.setItem('user', JSON.stringify(user))
            }else{
                localStorage.removeItem('user')
            }
            commit('SET_USER', user)
        },
        updateToken({commit}, {token}) {
            if(token !== ''){
                localStorage.setItem('token',token)
                Vue.prototype.$http.defaults.headers.Authorization = `Bearer ${token}`
            }else{
                localStorage.removeItem('token')
                delete Vue.prototype.$http.defaults.headers.Authorization
            }
            commit('SET_TOKEN', token)
        },
        updateAbility({commit}, {ability}) {
            if(ability.length !== 0){
                localStorage.setItem('ability', JSON.stringify(ability))
            }else{
                localStorage.removeItem('ability')
            }
            commit('SET_ABILITY', ability)
            abilityApp.update(ability)
        },
    },
}
