import Vue from 'vue'
import {VBToggle, ToastPlugin, ModalPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import 'vue-good-table/dist/vue-good-table.css';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
// Note: Vue automatically prefixes the directive name with 'v-'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// Global Mixin
import './mixins/generalMixin'

// 3rd party plugins
import '@/libs/axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'
import '@/libs/acl'

Vue.use(VueFlatPickr);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// directive
Vue.directive('b-toggle', VBToggle)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
