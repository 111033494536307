export default [
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/auth/Register.vue'),
        meta: {
            layout: 'full',
            pageTitle: "register",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Login",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/verify',
        name: 'verify',
        component: () => import('@/views/auth/Verify.vue'),
        meta: {
            layout: 'full',
            pageTitle: "verify_page.title",
            redirectIfLoggedIn: true,
        },
    },
]
