import axios from '@axios'
import Repository from "@appCore/repositories/Repository";

export default class AuthRepository extends Repository {
    url = "auth"

    register(payload) {
        return axios.post(`${this.url}/register`, payload);
    }
}
