import axios from '@axios'
import Repository from "@appCore/repositories/Repository";

class UtilsRepository extends Repository {
    url = "utils"

    permissions() {
        return axios.get(`${this.url}/permissions`);
    }

    addEditUserUtils() {
        return axios.get(`${this.url}/add-edit-user`);
    }
}

export default new UtilsRepository();
