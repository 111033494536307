export default [
    {
        path: '/categories-management',
        name: 'categories-management',
        component: () => import('@/views/categories-management/index.vue'),
        meta: {
            pageTitle: 'admin.category.manageCategories',
            breadcrumb: [
                {
                    text: 'admin.category.manageCategories',
                    active: true,
                },
            ],
            resource: 'categories',
            action: 'management',
        },
    },
]
