import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from '@appCore/store/theme/app'
import appConfig from '@appCore/store/theme/app-config'
import verticalMenu from '@appCore/store/theme/vertical-menu'
import lang from '@appCore/store/lang'

Vue.use(Vuex)

export default {
    app,
    appConfig,
    verticalMenu,
    lang,
}
