import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function (response) {
    if (!response) {
        Vue.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
                title: 'Error',
                icon: 'TrashIcon',
                variant: 'danger',
                text: error.message,
            },
        })
        return Promise.reject(error.message)
    }

    if (response.status === 404) {
        window.location.href = "/pages-404";
        return Promise.reject(error.message)
    }

    if (response.status === 401) {
        // store.dispatch('auth/logout')
        window.location.href = "/login";
        return Promise.reject(error.message)
    }

    if (response.status === 442) {
        return 0;
    }

    if (!response.data.errors) {
        let message = response.data.message
        if (Array.isArray(response.data.message)) {
            message = message[0]
        }
        Vue.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
                title: 'Error',
                icon: 'TrashIcon',
                variant: 'danger',
                text: message,
            },
        })
        return Promise.reject(message)
    }

    return 0
}
