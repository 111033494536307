import CrudRepository from "@/repositories/CrudRepository";
import axios from "@/libs/axios";

export default class ContractRepository extends CrudRepository {
    url = 'contracts';

    getPdf(id) {
        return axios.get(`${this.url}/${id}/pdf`);
    }
}
