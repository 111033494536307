export default [
    {
        path: '/team-creation',
        name: 'team-creation',
        component: () => import('@/views/auth/CreateTeam.vue'),
        meta: {
            layout: 'full',
            pageTitle: "create_team_page.title",
            canNavigate: true,
        },
    },
]
