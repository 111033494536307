export default [
    {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import('@appCore/views/pages/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Not Authorized",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/pages-404',
        name: 'pages-404',
        component: () => import('@appCore/views/pages/Error404.vue'),
        meta: {
            layout: 'full',
            pageTitle: "Page 404",
        },
    },
]
