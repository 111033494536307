import axios from '@axios'
import Repository from "@appCore/repositories/Repository";

export default class CrudRepository extends Repository {
    getAll(params) {
        return axios.get(`${this.url}`, {params});
    }

    get(id, params = null) {
        return axios.get(`${this.url}/${id}`, {params});
    }

    store(payload) {
        return axios.post(`${this.url}`, payload);
    }

    update(id, payload) {
        return axios.put(`${this.url}/${id}`, payload);
    }

    delete(id) {
        return axios.delete(`${this.url}/${id}`)
    }
}
