import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BRow,
    BCard,
    BTable,
    BPagination,
    BSidebar,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BCardHeader,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Loading from "@appCore/components/Loading.vue";
import store from "@/store";

localize(store.getters['lang/lang'])
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('b-button', BButton)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-card-title', BCardTitle)
Vue.component('b-card-text', BCardText)
Vue.component('b-img', BImg)
Vue.component('b-link', BLink)
Vue.component('validation-provider',ValidationProvider)
Vue.component('validation-observer',ValidationObserver)
Vue.component('vuexy-logo', VuexyLogo)
Vue.component('b-card', BCard)
Vue.component('v-select', vSelect)
Vue.component('b-table', BTable)
Vue.component('b-pagination', BPagination)
Vue.component('b-sidebar', BSidebar)
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-card-header', BCardHeader)
Vue.component("loading", Loading)
