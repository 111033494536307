import Vue from 'vue'
import VueRouter from 'vue-router'
import appCoreRouter from '@appCore/router'
import customAppCore from './routes/custom-app-core'
import contractManagementRoute from './routes'
import store from "@/store";
import {canNavigate} from "@/libs/acl/routeProtection";

Vue.use(VueRouter)

const router = new VueRouter({
    ...appCoreRouter.prop,
    ...{
        routes: [
            ...contractManagementRoute,
            ...customAppCore,
            {
                path: '*',
                redirect: 'pages-404',
            },
        ],
    }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to) => {
    appCoreRouter.afterEach(to)
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = store.getters['auth/isLoggedIn'];
    const forceRedirect = store.getters['redirect/redirect'];

    if (forceRedirect !== '' && to.name !== forceRedirect) {
        return next({name: forceRedirect})
    }

    if (isLoggedIn && to.meta.redirectIfLoggedIn) {
        return next({name: 'home'})
    }

    if (!to.meta.redirectIfLoggedIn) {
        if (!isLoggedIn) {
            return next({name: 'login'})
        }
        if (!to.meta.canNavigate && !canNavigate(to)) {
            return next({name: 'not-authorized'})
        }
    }
    return next()
})

export default router
