export default [
    {
        path: '/contracts',
        name: 'contracts',
        component: () => import('@/views/contracts/index.vue'),
        meta: {
            pageTitle: "user.contracts.contracts",
            breadcrumb: [
                {
                    text: 'user.contracts.contractsOverview',
                    active: true,
                },
            ],
            resource: 'contracts',
            action: 'index',
        },
    },
    {
        path: '/contracts/add-update/:id?',
        name: 'update-contract',
        component: () => import('@/views/contracts/add-update.vue'),
        meta: {
            pageTitle: "user.contracts.contract",
            breadcrumb: [
                {
                    text: 'user.contracts.contracts',
                    to: {name: 'contracts'},
                    active: false,
                },
                {
                    text: 'user.contracts.contract',
                    active: true,
                },
            ],
            resource: 'contracts',
            action: 'create',
        },
    },
    {
        path: '/contracts/show/contract/:id',
        name: 'show-contract',
        component: () => import('@/views/contracts/show.vue'),
        meta: {
            pageTitle: 'user.contracts.contract',
            breadcrumb: [
                {
                    text: 'user.contracts.contracts',
                    to: {name: 'contracts'},
                    active: false,
                },
                {
                    text: 'user.contracts.contract',
                    active: true,
                },
            ],
            resource: 'contracts',
            action: 'index',
        },
    },
]
