import axios from '@axios'
import CrudRepository from "@/repositories/CrudRepository";

export default class InvitationRepository extends CrudRepository {
    url = 'invitations';

    getInvitationInfo(payload) {
        return axios.post(`${this.url}/invitation-info`, payload);
    }
}
