import auth from './auth'
import other from "./other";
import categoriesManagement from "./categories-management";
import rolesManagement from "./roles-management"
import contracts from "./contracts";

export default [
    ...auth,
    ...other,
    ...rolesManagement,
    ...categoriesManagement,
    ...contracts,
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/settings/index.vue'),
        meta: {
            pageTitle: "user.settings.settings",
            resource: 'user-dashboard',
            action: 'index',
        },
    },
]
