import Vue from 'vue'
import ability from "@appCore/libs/acl/ability";
import store from '@/store/index'
import RepositoryFactory from "@/repositories/RepositoryFactory";

Vue.mixin({
    methods: {
        isUserLoggedIn() {
            return store.getters['auth/isLoggedIn'];
        },
        auth() {
            return store.getters['auth/user'];
        },
        getCurrentTeam() {
            return store.getters['auth/currentTeam'];
        },
        can(resource, action) {
            return ability.can(action || 'manage', resource)
        },
        getAppName() {
            return process.env.VUE_APP_Name
        },
        lang() {
            return store.getters['lang/lang'];
        },
        authRepository() {
            return RepositoryFactory.create('auth');
        },
        utilsRepository() {
            return RepositoryFactory.create('utils');
        },
    }
})
