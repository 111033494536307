export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@appCore/views/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
      canNavigate: true,
    },
  },
]
