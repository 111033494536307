import "@appCore/mixins/generalMixin"
import "@/mixins/permissions"
import "@/mixins/category-permissions"
import Vue from 'vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

Vue.mixin({
    data() {
        return {
            fieldsTypes: ['text', 'textarea', 'number', 'amount', 'date', 'link', 'list'],
            contractStatus: ['Draft', 'Active', 'Canceled ', 'Terminated'],
            durationTypes: ['Start date',
                'With end date and without automatic renewal',
                'With end date and automatic renewal',
                'Without End date']
        }
    },
    methods: {
        utilsAppRepository() {
            return RepositoryFactory.create('utilsResources');
        },
        isAdmin() {
            return this.auth().is_admin
        },
        isUser() {
            return !this.auth().is_admin
        },
        getCurrentTeam() {
            return this.$store.getters['auth/currentTeam']
        },
        getRoleName() {
            return this.isAdmin() ? this.auth().role : this.getCurrentTeam().role
        },
        hasAccess(access) {
            return !(this.isAdmin() && access === 0);
        },
        getFieldTypeText(type) {
            return this.fieldsTypes[type]
        },
        getContractStatus(status) {
            return this.contractStatus[status]
        },
        getDurationType(type) {
            return this.durationTypes[type]
        }
    }
})
