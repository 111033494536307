import Vue from 'vue'
import Vuex from 'vuex'
import customAppCore from './customAppCore'
import auth from '@/store/auth/index'
import redirect from '@/store/redirect/index'
import settings from "@/store/settings"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...customAppCore,
    auth,
    redirect,
    settings
  },
  strict: process.env.DEV,
})
