import axios from '@axios'
import Repository from "@appCore/repositories/Repository";

class AuthRepository extends Repository {
    url = "auth"

    login(payload) {
        return axios.post(`${this.url}/login`, payload);
    }

    logout() {
        return axios.post(`${this.url}/logout`);
    }

    forgotPassword(payload) {
        return axios.post(`${this.url}/forgot-password`, payload);
    }

    updateProfile(payload) {
        return axios.put(`${this.url}/update-profile`, payload);
    }

    resetPassword(payload) {
        return axios.post(`${this.url}/reset-password`, payload);
    }
}

export default new AuthRepository();
