import axios from '@axios'
import CrudRepository from "@/repositories/CrudRepository";

export default class SectionRepository extends CrudRepository {
    url = "sections"

    getUrlByCategories(categoryId){
        return `categories/${categoryId}/${this.url}`
    }

    getAll(categoryId,params) {
        const url = this.getUrlByCategories(categoryId)
        return axios.get(url, {params});
    }

    store(categoryId,payload) {
        const url = this.getUrlByCategories(categoryId)
        return axios.post(url, payload);
    }
}
